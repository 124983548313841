<template>
  <div>
    <NewGenericBTable
      :key="generic_table_key"
      :search_filter="false"
      :fields="fields"
      :pre_filter="pre_filter"
      :url="'/evaluations2/evaluation/'"
      @actual_items="FetchInstrument"
    >
      <template #cell(title)="row">
        <div
          v-if="!redirect_to_evaluatee_view"
          class="div-href"
          @click="redirectToEvaluation(row.item.id)"
        >
          {{ row.item.title }}
        </div>
        <div
          v-else-if="
            new_tests.some(
              (x) =>
                x.evaluation == row.item.id && x.visible_by_evaluatees === true
            )
          "
          class="div-href"
          @click="redirectToEvaluateeEvaluation(row.item.id)"
        >
          {{ row.item.title }}
        </div>
        <div v-else-if="redirect_to_evaluatee_view">
          <span
            v-b-tooltip.v-secondary.left.noninteractive="
              `${$getVisibleNames(
                'evaluations2.evaluation',
                false,
                'Instrumento'
              )} no disponible`
            "
          >
            {{ row.item.title }}
          </span>
        </div>
      </template>
      <template #cell(description)="row">
        <div class="rich-text-content" v-html="row.item.description"></div>
      </template>
      <template #cell(scope)="row">
        {{ findScopedId(row.item.scope_id) }}
      </template>
      <template #head(instrument)="head">
        <div class="d-none">{{ head.label }}</div>
      </template>
      <template #cell(instrument)="row">
        <div>
          <b-table-simple bordered small responsive hover>
            <b-thead>
              <b-tr>
                <b-th class="th-class">Instrumento</b-th>
                <b-th class="th-class">Puntaje</b-th>
                <b-th class="th-class">Ponderación</b-th>
                <b-th class="th-class">{{
                  $getVisibleNames(
                    "manual.test.agente_evaluativo",
                    false,
                    "Tipo"
                  )
                }}</b-th>
                <b-th class="th-class" v-if="evaluation_scoped == 1"
                  >Fecha de inicio</b-th
                >
                <b-th class="th-class">
                  <template>
                    <button-add
                      v-if="allows_crud && !row.item.test_creation_is_locked"
                      title="Crear Instrumento"
                      v-b-tooltip.v-secondary.left.noninteractive="
                        `Crear ${$getVisibleNames(
                          'evaluations2.test',
                          false,
                          'Instrumento'
                        )}`
                      "
                      @click="
                        $bvModal.show(`modal-created-instrument-${row.item.id}`)
                      "
                      size="sm"
                    >
                    </button-add>
                    <b-modal
                      :id="`modal-created-instrument-${row.item.id}`"
                      :title="`Crear ${$getVisibleNames(
                        'evaluations2.test',
                        false,
                        'Instrumento'
                      )}`"
                      size="xl"
                      @hide="confirmClose"
                      hide-footer
                    >
                      <NewInstrumentForm
                        :evaluation_id="row.item.id"
                        :show_step="
                          row.item.matter_evaluation != null ||
                          row.item.egress_profile_matter_evaluation != null
                        "
                        @change_evaluation_form="
                          () => (change_evaluation_form = true)
                        "
                        @closeModal="
                          $bvModal.hide(
                            `modal-created-instrument-${row.item.id}`
                          )
                        "
                      ></NewInstrumentForm>
                    </b-modal>
                  </template>
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <template v-if="filterTest(row.item.id).length > 0">
                <b-tr
                  v-for="item in filterTest(row.item.id)"
                  :key="'instrument:' + item.id"
                >
                  <b-td class="td-class">
                    <div
                      v-if="!redirect_to_evaluatee_view"
                      class="div-href"
                      @click="redirectToEvaluation(row.item.id, item.id)"
                    >
                      {{ item.title }}
                    </div>
                    <div
                      v-else-if="item.visible_by_evaluatees"
                      class="div-href"
                      @click="
                        redirectToEvaluateeEvaluation(row.item.id, item.id)
                      "
                    >
                      {{ item.title }}
                    </div>
                    <div v-else-if="redirect_to_evaluatee_view">
                      <span
                        v-b-tooltip.v-secondary.left.noninteractive="
                          `${$getVisibleNames(
                            'evaluations2.test',
                            false,
                            'Instrumento'
                          )} no disponible`
                        "
                      >
                        {{ item.title }}
                      </span>
                    </div>
                  </b-td>
                  <b-td class="td-class">
                    {{ item.max_score }}
                  </b-td>
                  <b-td class="td-class">
                    {{ item.weighing }}
                    %
                  </b-td>
                  <b-td class="td-class">
                    <span v-b-tooltip.v-secondary.left.noninteractive="'Tipo'">
                      <span v-if="item.evaluative_agent">
                        {{
                          (
                            typesDefault.find(
                              (x) => x.id == item.evaluative_agent
                            ) || {}
                          ).name || "N/A"
                        }}
                      </span>
                      <span v-else> N/A </span>
                    </span>
                  </b-td>
                  <b-td class="td-class" v-if="evaluation_scoped == 1">
                    <span v-if="item.start_time">
                      {{ item.start_time | FormatToDateTime }} </span
                    ><span v-else>No cuenta con Fecha de Inicio</span>
                  </b-td>
                  <b-td class="td-class">
                    <button-edit
                      size="sm"
                      v-if="allows_crud && !item.is_locked"
                      v-b-tooltip.v-secondary.topright.noninteractive="
                        `Editar ${$getVisibleNames(
                          'evaluations2.test',
                          false,
                          'Instrumento'
                        )}`
                      "
                      @click="$bvModal.show(`modal-edit-instrument-${item.id}`)"
                    ></button-edit>
                    <button-delete
                      v-if="
                        allows_crud &&
                        !(
                          existEvaluateeEvaluation(row.item.id) ||
                          existEvaluateeTest(item.id)
                        ) &&
                        !item.is_locked
                      "
                      size="sm"
                      v-b-tooltip.v-secondary.topright.noninteractive="
                        `Eliminar ${$getVisibleNames(
                          'evaluations2.test',
                          false,
                          'Instrumento'
                        )}`
                      "
                      @click="askForDeleteInstrument(item.id)"
                    ></button-delete>
                    <b-modal
                      :id="`modal-edit-instrument-${item.id}`"
                      :title="`Editar ${$getVisibleNames(
                        'evaluations2.test',
                        false,
                        'Instrumento'
                      )}`"
                      size="xl"
                      hide-footer
                    >
                      <NewInstrumentForm
                        :evaluation_id="row.item.id"
                        :Instrument="item"
                        :show_step="
                          row.item.matter_evaluation != null ||
                          row.item.egress_profile_matter_evaluation != null
                        "
                        :ClosedEvaluatee="existEvaluateeTest(item.id)"
                        @closeModal="
                          $bvModal.hide(`modal-edit-instrument-${item.id}`)
                        "
                      ></NewInstrumentForm>
                    </b-modal>
                  </b-td>
                </b-tr>
              </template>
              <template v-else>
                <b-tr>
                  <b-td :colspan="6">
                    No hay
                    {{
                      $getVisibleNames(
                        "evaluations2.test",
                        true,
                        "Instrumentos"
                      )
                    }}
                    para esta
                    {{
                      $getVisibleNames(
                        "evaluations2.evaluation",
                        false,
                        "Evaluación"
                      )
                    }}.</b-td
                  >
                </b-tr>
              </template>
            </b-tbody>
          </b-table-simple>
        </div>
      </template>
      <template #cell(actions)="row">
        <div class="container-edit">
          <button-edit
            :title="`Editar ${$getVisibleNames(
              'teaching.evaluation',
              false,
              'Evaluación'
            )}`"
            size="sm"
            class="evaluation-edit mb-2"
            v-can="'teaching.change_evaluation'"
            v-if="allows_crud && !row.item.is_locked"
            v-b-tooltip.v-secondary.topright.noninteractive="
              `Editar ${$getVisibleNames(
                'teaching.evaluation',
                false,
                'Evaluación'
              )}.`
            "
            @click="$bvModal.show(`modal-edit-evaluation-${row.item.id}`)"
          ></button-edit>
          <button-delete
            v-if="
              allows_crud &&
              !existEvaluateeEvaluation(row.item.id) &&
              !row.item.is_locked
            "
            size="sm"
            class="evaluation-delete mb-2"
            v-can="'evaluations2.delete_evaluation'"
            v-b-tooltip.v-secondary.topright.noninteractive="
              `Eliminar ${$getVisibleNames(
                'teaching.evaluation',
                false,
                'Evaluación'
              )}.`
            "
            @click="askForDeleteEvaluation(row.item.id)"
          ></button-delete>
          <b-modal
            :id="`modal-edit-evaluation-${row.item.id}`"
            :title="`Editar ${$getVisibleNames(
              'evaluations2.evaluation',
              false,
              'Evaluación'
            )}`"
            hide-footer
            size="xl"
          >
            <NewEvaluationForm
              :Evaluation="row.item"
              :evaluation_scoped_id="evaluation_scoped"
              :evaluation_scoped_object_id="evaluation_scoped_object_id"
              :Sections="Sections"
              :Matters="Matters"
              :show_step="
                row.item.matter_evaluation != null ||
                row.item.egress_profile_matter_evaluation != null ||
                row.item.scope == 1
              "
              :ClosedEvaluatee="existEvaluateeEvaluation(row.item.id)"
              :EgressProfileMatters="EgressProfileMatters"
              @closeModal="closeModal()"
            ></NewEvaluationForm>
          </b-modal>
        </div>
      </template>
    </NewGenericBTable>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";
export default {
  name: "ManageEvaluationTable",
  components: {
    NewGenericBTable: () => import("@/components/reusable/NewGenericBTable"),
    NewEvaluationForm: () =>
      import(
        "@/components/new-evaluations/ManageEvaluations/NewEvaluationForm"
      ),
    NewInstrumentForm: () =>
      import("@/components/new-evaluations/Evaluation/NewInstrumentForm"),
  },
  props: {
    evaluation_scoped: {
      type: Number,
      required: true,
    },
    evaluation_scoped_object_id: {
      type: Number,
    },
    pre_filter: {
      type: String,
      required: true,
    },
    allows_crud: {
      required: true,
      type: Boolean,
    },
    Sections: {
      type: Array,
    },
    Matters: {
      type: Array,
    },
    EgressProfileMatters: {
      type: Array,
    },
    redirect_to_evaluatee_view: {
      default: false,
      required: false,
    },
  },
  data() {
    return {
      generic_table_key: 0,
      prevent_close_modal: false,
      change_evaluation_form: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      evaluation_types: names.NEW_EVALUATION_TYPES,
      evaluation_categories: names.NEW_EVALUATION_CATEGORIES,
      new_tests: names.NEW_TESTS,
      egress_profiles: names.EGRESS_PROFILES,
      careers: names.CAREERS,
      faculties: names.FACULTIES,
      campuses: names.CAMPUSES,
      typesDefault: names.NEW_TEST_TYPES,
      evaluatee_evaluations: names.EVALUATEE_EVALUATIONS,
      evaluatee_tests: names.EVALUATEE_TESTS,
    }),
    fields() {
      return [
        {
          key: "title",
          label: "Evaluación",
          sortable: true,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "description",
          label: "Descripción",
          sortable: false,
          display_column: false,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "scope",
          label: this.dinamicHeader(),
          sortable: false,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
        },
        {
          key: "type",
          label: "Tipo",
          sortable: false,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            return (
              (this.evaluation_types.find((x) => x.id == value) || {}).value ||
              "N/A"
            );
          },
        },
        {
          key: "weighing",
          label: "Ponderación",
          sortable: false,
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          formatter: (value) => {
            return value == null || value == 0 ? "N/A" : value + "%";
          },
        },
        // {
        //   key: "category",
        //   label: "Categoría",
        //   sortable: false,
        //   display_column: false,
        //   thStyle: { background: "var(--primary-color) !important" },
        //   formatter: (value) => {
        //     return (
        //       (this.evaluation_categories.find((x) => x.id == value) || {})
        //         .value || "N/A"
        //     );
        //   },
        // },
        {
          key: "instrument",
          label: this.$getVisibleNames(
            "evaluations2.test",
            true,
            "Instrumentos"
          ),
          display_column: true,
          thStyle: { background: "var(--primary-color) !important" },
          tdClass: "text-center",
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thStyle: "width: 1%;",
          thClass: "noprint",
          tdClass: "text-center noprint",
        },
      ];
    },
  },
  methods: {
    confirmClose(bvModalEvt) {
      if (
        bvModalEvt.trigger === "headerclose" ||
        bvModalEvt.trigger === "event"
      ) {
        this.change_evaluation_form = false;
        return;
      }
      if (!this.prevent_close_modal && this.change_evaluation_form) {
        bvModalEvt.preventDefault();
        this.$bvModal
          .msgBoxConfirm(
            "¿Estás seguro de que quieres cerrar? Los datos no guardados se perderán.",
            {
              title: "Confirmación Necesaria",
              size: "md",
              buttonSize: "md",
              okVariant: "warning",
              okTitle: "Sí, cerrar",
              cancelTitle: "Cancelar",
              footerClass: "p-2",
              hideHeaderClose: true,
              centered: true,
              headerClass: "text-black",
              header: {
                title: "Confirmación Necesaria",
                class: "text-center",
              },
            }
          )
          .then((value) => {
            if (value) {
              this.prevent_close_modal = true;
              this.$bvModal.hide(bvModalEvt.componentId);
              this.$nextTick(() => {
                this.change_evaluation_form = false;
                this.prevent_close_modal = false;
              });
            }
          });
      }
    },
    filterTest(evaluation_id) {
      return this.new_tests.filter((x) => x.evaluation == evaluation_id);
    },
    // instrumentWeighing(score, evaluation_id) {
    //   const maxScore = this.new_tests
    //     .filter((x) => x.evaluation == evaluation_id)
    //     .reduce((accumulator, x) => {
    //       return accumulator + x.max_score;
    //     }, 0);
    //   if (maxScore == 0) return 0;
    //   return (score / maxScore) * 100;
    // },
    existEvaluateeEvaluation(evaluation_id) {
      if (
        this.evaluatee_evaluations.filter((x) => x.evaluation == evaluation_id)
          .length > 0
      )
        return true;
      else return false;
    },
    existEvaluateeTest(test_id) {
      if (
        this.evaluatee_tests.filter(
          (x) =>
            x.test == test_id &&
            (x.start_time != null ||
              x.is_corrected ||
              x.is_finished ||
              x.is_published)
        ).length > 0
      )
        return true;
      else return false;
    },
    FetchInstrument(items) {
      const evaluations_ids = items.map((x) => x.id);
      this.$store
        .dispatch(names.FETCH_NEW_TESTS, {
          evaluations_ids: evaluations_ids,
        })
        .then((response) => {
          this.$store.dispatch(names.FETCH_EVALUATEE_TESTS, {
            tests_ids: response.map((x) => x.id),
          });
        });
      this.$store.dispatch(names.FETCH_EVALUATEE_EVALUATIONS, {
        evaluations_ids: evaluations_ids,
      });
    },
    redirectToEvaluation(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "NewEvaluationView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    redirectToEvaluateeEvaluation(evaluation_id, instrument_id = null) {
      this.$router.push({
        name: "EvaluateeView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      });
    },
    closeModal(evaluation_id) {
      this.$bvModal.show(`modal-edit-evaluation-${evaluation_id}`);
      this.generic_table_key++;
    },
    askForDeleteEvaluation(evaluation_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar la <b>${this.$getVisibleNames(
          "evaluations2.evaluation",
          false,
          "Evaluación"
        ).toLowerCase()} y sus instrumentos creados</b>?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_NEW_EVALUATION, evaluation_id)
            .then(() => {
              this.generic_table_key++;
              toast(
                "Se eliminó la " +
                  this.$getVisibleNames(
                    "evaluations2.evaluation",
                    false,
                    "Evaluación"
                  )
              );
            });
        }
      });
    },
    askForDeleteInstrument(instrument_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar el <b>${this.$getVisibleNames(
          "evaluations2.test",
          false,
          "Instrumento"
        ).toLowerCase()}</b>?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_NEW_TEST, instrument_id);
          toast(
            "Se eliminó el " +
              this.$getVisibleNames("evaluations2.test", false, "Instrumento")
          );
        }
      });
    },
    dinamicHeader() {
      if (this.evaluation_scoped == 1)
        return this.$getVisibleNames("teaching.section", true, "Sección");
      else if (this.evaluation_scoped == 2)
        return this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignatura"
        );
      else if (this.evaluation_scoped == 3)
        return this.$getVisibleNames(
          "teaching.matter",
          true,
          "Asignatura Base"
        );
      else if (this.evaluation_scoped == 4)
        return this.$getVisibleNames(
          "mesh.egressprofile",
          true,
          "Perfil De Egreso"
        );
      else if (this.evaluation_scoped == 5)
        return this.$getVisibleNames("mesh.career", true, "Programa");
      else if (this.evaluation_scoped == 6)
        return this.$getVisibleNames("mesh.faculty", true, "Facultad");
      else if (this.evaluation_scoped == 7)
        return this.$getVisibleNames("mesh.campus", true, "Sede");
      else return "";
    },
    findScopedId(scoped_id) {
      if (this.evaluation_scoped == 1)
        return (this.Sections.find((x) => x.id == scoped_id) || {}).name || "";
      else if (this.evaluation_scoped == 2)
        return (
          (this.EgressProfileMatters.find((x) => x.id == scoped_id) || {})
            .matter_name || ""
        );
      else if (this.evaluation_scoped == 3)
        return (this.Matters.find((x) => x.id == scoped_id) || {}).name || "";
      else if (this.evaluation_scoped == 4)
        return (
          (this.egress_profiles.find((x) => x.id == scoped_id) || {}).name || ""
        );
      else if (this.evaluation_scoped == 5)
        return (this.careers.find((x) => x.id == scoped_id) || {}).name || "";
      else if (this.evaluation_scoped == 6)
        return (this.faculties.find((x) => x.id == scoped_id) || {}).name || "";
      else if (this.evaluation_scoped == 7)
        return (this.campuses.find((x) => x.id == scoped_id) || {}).name || "";
      else return "";
    },
  },
  created() {},
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.btn-details,
.btn-instrument {
  display: table;
  margin: 0 auto;
  min-width: 10.5em;
  background-color: var(--kl-primary-button-color) !important;
  color: #fff;
}
.btn-details:hover,
.btn-instrument:hover {
  background-color: var(--kl-primary-button-hover-color) !important;
}
.container-edit {
  display: flex;
  flex-direction: column;
  margin-right: 5px;
}
.evaluation-edit,
.evaluation-delete {
  background-color: var(--kl-primary-button-color);
  color: var(--secondary-font-color);
  width: 2em;
  margin-right: 0 !important;
  max-height: 30px;
}
.evaluation-edit:hover {
  background: var(--kl-primary-button-hover-color);
}
.evaluation-delete {
  background-color: #dc3545 !important;
}
.evaluation-delete:hover {
  background-color: #e45d6b !important;
}
.th-class {
  background: var(--primary-color) !important;
}
.div-href {
  color: #007bff;
}
.div-href:hover {
  color: #0057b1 !important;
  text-decoration: underline;
  cursor: pointer;
}
</style>
